/* Default CSS Code */

* {
  margin: 0;
  padding: 0;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

main {
  min-height: 70vh;
}

:focus * {
  outline: 0;
}

.g-recaptcha > div > div{
  margin: 10px auto !important;
  text-align: center;
  width: auto !important;
  height: auto !important;
 }

button {
  cursor: pointer;
  border: 0;
  padding: 0;
}

button:focus {
  outline: 0;
}

.btn.focus,
.btn:focus {
  -webkit-box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.25);
  box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.25);
}

.form-group {
  margin-bottom: 0;
}

figure {
  margin: 0;
}

p {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  display: inline-block;
  color: #3d3d3d;
}

a:hover {
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
}

img {
  width: 100%;
  max-width: 100%;
  height: 100%;
  vertical-align: middle;
  -o-object-fit: cover;
  object-fit: cover;
}

video {
  width: 100%;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-weight: 600;
}

.container {
  max-width: 1090px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.row {
  padding: 5px;
}

/* Default CSS Code */

/* 

font-family: 'Montserrat', sans-serif;
font-family: 'Mulish', sans-serif;

*/

body {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  color: #3d3d3d;
  background: #fff;
}

/* Reusable Code */
.custom-btn {
  width: fit-content;
  min-width: 250px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 50px;
  font-weight: 500;
  background: #1a6f3f;
  color: #fff;
  border-radius: 17px;
  -webkit-border-radius: 17px;
  -moz-border-radius: 17px;
  -ms-border-radius: 17px;
  -o-border-radius: 17px;
}

.custom-btn--center {
  margin-left: auto;
  margin-right: auto;
}

.basic-area {
  padding: 200px 0;
}

.basic-flex {
  display: flex;
  align-items: center;
  gap: 60px;
}

.basic-flex--center {
  flex-direction: row;
  align-content: center;
  justify-content: center;
}

.basic-flex--reverse {
  flex-direction: row-reverse;
}

.basic-flex__image {
  width: 320px;
  height: 680px;
}

.basic-flex__content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.basic-flex__content h2 {
  font-size: 40px;
  line-height: 50px;
  text-align: center;
  font-weight: 600;
}

/* Reusable Code */

/* Header Area */
.header {
  padding: 24px 0;
}

.nav {
  position: relative;
  display: flex;
  align-items: center;
  gap: 275px;
}

.nav__links {
  display: flex;
  align-items: center;
  gap: 30px;
}

.nav__logo {
  width: fit-content;
  height: 40px;
}

.nav__logo img {
  object-fit: contain;
}

.nav__link {
  font-weight: 500;
  position: relative;
  padding-bottom: 5px;
}

.nav__link--active {
  color: #1a6f3f;
  font-weight: 600;
}

.nav__link::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background: #1a6f3f;
  transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
}

.nav__link--active::after {
  width: 100%;
}

.navbar-toggler {
  position: absolute;
  width: 24px;
  height: 14px;
  top: 13px;
  right: 0;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  background: transparent;
  border: 0;
  z-index: 999;
  display: none;
}

.navbar-toggler .toggler-btn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.toggler-btn .bar {
  width: 100%;
  display: block;
  height: 2px;
  background: #6c6c6c;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  margin-bottom: 4px;
}

.toggler-btn .bar:nth-child(3) {
  margin-bottom: 0;
  width: 50%;
  margin-left: auto;
}

.navbar-toggler.change .toggler-btn .bar1 {
  background: #6c6c6c;
  transform: rotate(-45deg) translate(-5px, 5px);
  -webkit-transform: rotate(-45deg) translate(-5px, 5px);
  -moz-transform: rotate(-45deg) translate(-5px, 5px);
  -ms-transform: rotate(-45deg) translate(-5px, 5px);
  -o-transform: rotate(-45deg) translate(-5px, 5px);
}

.navbar-toggler.change .toggler-btn .bar2 {
  opacity: 0;
}

.navbar-toggler.change .toggler-btn .bar3 {
  background: #6c6c6c;
  transform: rotate(45deg) translate(-4px, -4px);
  -webkit-transform: rotate(45deg) translate(-4px, -4px);
  -moz-transform: rotate(45deg) translate(-4px, -4px);
  -ms-transform: rotate(45deg) translate(-4px, -4px);
  -o-transform: rotate(45deg) translate(-4px, -4px);
  width: 100%;
  margin-left: 0;
}

/* Header Area */

/* Hero Area */

.hero-area {
  padding-top: 50px;
  padding-bottom: 110px;
  position: relative;
  overflow: hidden;
}

.hero-area .container {
  position: relative;
  z-index: 50;
}

.hero-circle {
  position: absolute;
  bottom: -260px;
  right: -450px;
  width: 1111px;
  height: 1111px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  background: rgba(26, 111, 63, 0.02);
  z-index: 10;
}

.activity-code {
  display: flex;
  align-items: center;
  gap: 14px;
  width: 550px;
  height: 54px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;
  padding: 4px 14px;
  padding-right: 0px;
  background: #1a6f3f;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
}

.activity-code p {
  font-size: 18px;
  line-height: 28px;
  color: #fff;
  width: fit-content;
}

.activity-code__wrapper {
  position: relative;
  flex: 1;
  height: 46px;
}

.activity-code__input {
  width: 100%;
  height: 100%;
  padding: 13px 21px;
  /* padding-right: 90px; */
  background: #f6f7f9;
  border-radius: 64px;
  -webkit-border-radius: 64px;
  -moz-border-radius: 64px;
  -ms-border-radius: 64px;
  -o-border-radius: 64px;
  border: 0;
  box-shadow: none;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  color: black;
}

::-webkit-input-placeholder {
  color: #6c6c6c;
}

::-moz-placeholder {
  color: #6c6c6c;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #6c6c6c;
}

:-moz-placeholder {
  color: #6c6c6c;
}

.activity-code__btn {
  position: absolute;
  top: 50%;
  right: 0;
  font-size: 24px;
  line-height: 34px;
  font-weight: 500;
  background: #1a6f3f;
  color: #fff;
  width: 75px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

@media screen and (max-width: 768px) {
  /* Adjust the max-width as needed for your design */
  .activity-code-container label {
    display: none;
  }
  .activity-code-container input {
    margin: 10px;
  }
  .activity-code-container {
    background-color: transparent !important;
  }
}

.hero__content {
  max-width: 894px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.hero__content h1 {
  font-size: 48px;
  line-height: 60px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 45px;
}

.hero__content h1 span {
  display: block;
}

.hero__image {
  margin-top: 170px;
  width: 100%;
  height: 450px;
}

/* Hero Area */

/* Activities area */
.activities-area {
  background: #fff2ae;
}

.activities {
  align-items: flex-start;
}

.activities .basic-flex__content {
  padding-top: 80px;
}

/* Activities area */

/* Share QR Area */
.share-qr-area {
  background: #bde0cc;
}
/* Share QR Area */

/* Growth Area */
.growth-area {
  background: #dce7f0;
}
/* Growth Area */

/* Download App Area */
.download-app-area {
  padding: 65px 0;
  background: rgba(108, 108, 108, 0.1);
}

.download-app {
  text-align: center;
}
.downloadApp {
  color: white;
}

.download-app h2 {
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 24px;
}

/* Download App Area */

/* Footer Area */
.footer-area {
  background: #3d3d3d;
  color: #fff;
  padding-top: 50px;
}

.footer {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.footer__logo {
  width: fit-content;
  height: 114px;
}

.footer__logo img {
  object-fit: contain;
}

.footer__nav-area {
  display: flex;
  gap: 90px;
}

.footer__nav h5 {
  font-family: "Mulish", sans-serif;
  font-size: 18px;
  line-height: 22px;
  color: #fff;
  margin-bottom: 20px;
}

.footer__nav-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.footer__nav-link {
  font-family: "Mulish", sans-serif;
  font-weight: 400;
  color: #fff;
}

.social-list {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 38px;
}

.social-list li a img {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.footer-rights {
  background: #292929;
  padding: 16px 0;
  margin-top: 50px;
}

.footer-rights p {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
}

/* Footer Area */

/* Responsive Code */
@media (max-width: 1199.98px) {
  .container {
    max-width: 100%;
    padding: 0 50px;
  }

  .nav__links {
    display: none;
  }

  .navbar-toggler {
    display: block;
  }

  .mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #fff;
    z-index: 9999;
  }

  .mobile-menu .nav {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 50px;
  }

  .mobile-menu .nav__links {
    display: flex;
    flex-direction: column;
  }

  .basic-flex {
    flex-direction: column-reverse;
  }

  .basic-flex__image {
    width: 396px;
    height: 600px;
  }

  .basic-area {
    padding: 100px 0;
  }

  .activities {
    align-items: center;
  }

  .activities .basic-flex__content {
    padding-top: 0;
  }

  .hero__image {
    height: auto;
  }

  .basic-flex__content {
    gap: 25px;
  }

  .basic-flex__content h2 {
    font-size: 28px;
    line-height: 38px;
  }

  .hero__image {
    margin-top: 100px;
  }

  .hero__content {
    max-width: 100%;
  }
}

@media (max-width: 767.98px) {
  .container {
    padding: 0 20px;
  }

  .activity-code p {
    display: none;
  }

  .activity-code {
    padding-left: 0;
    width: 100%;
    background: transparent;
  }

  .hero__content h1 {
    font-size: 32px;
    line-height: 42px;
    margin-bottom: 20px;
  }

  .hero__image {
    margin-top: 60px;
  }

  .basic-flex__content h2 {
    font-size: 24px;
    line-height: 34px;
  }

  .basic-flex {
    gap: 40px;
  }

  .basic-flex__image {
    width: 274px;
    height: 523px;
  }

  .basic-area {
    padding: 80px 0;
  }

  .footer {
    flex-direction: column;
    gap: 50px;
  }

  .social-list {
    position: absolute;
    top: 0;
    right: 0;
  }

  .footer__nav-area {
    gap: 70px;
  }

  .hero-circle {
    display: none;
  }

  .nav__links {
    gap: 25px;
  }

  .nav__link {
    padding-bottom: 4px;
  }
}
/* Responsive Code */

.green-star {
  color: #1a6f3f;
}
.grey-star {
  color: #cacaca;
}
.user-layout {
  display: flex;
  margin: var(--m2);
  justify-content: flex-end;
}
.center {
  text-align: center;
  display: inline-block;
  justify-content: center;
  align-items: center;
}
.loading-svg {
  height: 100px;
  position: absolute;
  top: 20%;
  left: 45%;
}
.logo {
  margin: 10px;
}
.card-body-carousel {
  margin: 36px 100px;
}
.fa-icon-color {
  color: green;
}
.card-shadow {
  transition: 0.3s;
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.card-body-carousel .carousel.carousel-slider {
  /* padding-bottom: 40px; */
  padding: 1rem 1rem 40px 1rem;
}
.card-body-carousel .carousel .control-dots .dot {
  width: 12px;
  height: 12px;
  background: green;
}
.card-shadow-1 {
  box-shadow: 1px 1px 10px 3px rgb(0 0 0 / 10%);
  transition: 0.3s;
  border-radius: 15px;
}
.slider-wrapper.axis-horizontal {
  box-shadow: 1px 1px 10px 3px rgb(0 0 0 / 10%);
  transition: 0.3s;
  border-radius: 15px;
}
.survey-text {
  padding-left: 220px;
  font-weight: 600;
}
.carousel .control-dots {
  bottom: -15px;
  left: -20px;
}
.input-label {
  margin-right: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.41px;

  /* Text/dark grey */

  color: #3d3d3d;
}
.feedback-button,
.feedback-button:focus,
.feedback-button:active {
  outline: none;
  margin-bottom: 5px;
  color: white;
  font-size: 18px;
  cursor: pointer;
  font-weight: 500;
  padding: 4px 8px;
  box-shadow: none;
  border-radius: 5px;
  border: 1px solid green;
  background-color: green;
}
.surveyCode {
  font-size: 20px;
  margin-right: 3px;
}
.carousel .slide {
  background: transparent;
}
.carousel .slide.selected .card {
  border: none;
}
@media (max-width: 700px) {
  .feedback-button {
    margin-top: 5px;
  }
  .activity-card {
    padding: 2%;
  }
}
@media (max-width: 768px) {
  .card-body-carousel {
    margin: 10px;
  }
  .survey-text {
    padding-left: 0;
    text-align: center;
  }
  .carousel .control-dots {
    left: -20px;
  }
  .surveyCode {
    font-size: 18px;
    margin-right: 3px;
    width: 70%;
  }
}

.activity-card {
  padding: 5%;
}
.close {
  position: absolute;
  right: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
  opacity: 0.3;
}
.close:hover {
  opacity: 1;
}
.close:before,
.close:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 33px;
  width: 2px;
  background-color: #333;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}
.betaChip {
  margin-left: 5px;
  margin-top: 5px;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.app-btn img {
  width: 100%;
  height: 100%;
}

.App {
  text-align: left;
}
body {
  font-family: "Montserrat";
}
.green-star {
  color: #1a6f3f;
}
.grey-star {
  color: #cacaca;
}
.user-layout {
  display: flex;
  margin: var(--m2);
  justify-content: flex-end;
}
.center {
  text-align: center;
  display: inline-block;
  justify-content: center;
  align-items: center;
}
.loading-svg {
  height: 100px;
  position: absolute;
  top: 20%;
  left: 45%;
}
.logo {
  margin: 10px;
}
.card-body-carousel {
  margin: 36px 100px;
}
.fa-icon-color {
  color: green;
}
.card-shadow {
  transition: 0.3s;
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.card-body-carousel .carousel.carousel-slider {
  /* padding-bottom: 40px; */
  padding: 1rem 1rem 40px 1rem;
}
.card-body-carousel .carousel .control-dots .dot {
  width: 12px;
  height: 12px;
  background: green;
}
.card-shadow-1 {
  box-shadow: 1px 1px 10px 3px rgb(0 0 0 / 10%);
  transition: 0.3s;
  border-radius: 15px;
}
.slider-wrapper.axis-horizontal {
  box-shadow: 1px 1px 10px 3px rgb(0 0 0 / 10%);
  transition: 0.3s;
  border-radius: 15px;
}
.survey-text {
  padding-left: 220px;
  font-weight: 600;
}
.carousel .control-dots {
  bottom: -15px;
  left: -20px;
}
.input-label {
  margin-right: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.41px;

  /* Text/dark grey */

  color: #3d3d3d;
}
.feedback-button,
.feedback-button:focus,
.feedback-button:active {
  outline: none;
  color: white;
  font-size: 18px;
  cursor: pointer;
  font-weight: 500;
  padding: 4px 8px;
  box-shadow: none;
  border-radius: 5px;
  border: 1px solid green;
  background-color: green;
}
.surveyCode {
  font-size: 20px;
  margin-right: 3px;
}
.carousel .slide {
  background: transparent;
}
.carousel .slide.selected .card {
  border: none;
}
@media (max-width: 700px) {
  .feedback-button {
    margin-top: 5px;
  }
  .activity-card {
    padding: 2%;
  }
}
@media (max-width: 768px) {
  .card-body-carousel {
    margin: 10px;
  }
  .survey-text {
    padding-left: 0;
    text-align: center;
  }
  .carousel .control-dots {
    left: -20px;
  }
  .surveyCode {
    font-size: 18px;
    margin-right: 3px;
    width: 70%;
  }
}

.activity-card {
  padding: 5%;
}
.close {
  position: absolute;
  right: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
  opacity: 0.3;
}
.close:hover {
  opacity: 1;
}
.close:before,
.close:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 33px;
  width: 2px;
  background-color: #333;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}
.betaChip {
  margin-left: 5px;
  margin-top: 5px;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.app-btn img {
  width: 100%;
  height: 100%;
}
.toast-body {
  width: 100% !important;
}

.activity-code-container {
  display: flex;
  align-items: center;
  padding: 2px;
  background-color: #1a6f3f; /* Example green background color */
  border-radius: 5px;
  width: 90%; /* Adjust the width as per your requirement */
  margin: auto; /* Center the container on the page */
  margin-bottom: 20px;
}
.activity-code-container-inner {
  display: flex;
  align-items: center;
  padding: 2px;
  background-color: #1a6f3f; /* Example green background color */
  border-radius: 5px;
  width: 90%; /* Adjust the width as per your requirement */
  margin: auto; /* Center the container on the page */
}

.activity-code-container label {
  color: white;
  text-wrap: nowrap;
  margin-right: 15px;
  margin-left: 15px;
  width: fit-content;
  font-size: 18px;
}

.activity-code-container input {
  border: 1px solid #fff; /* White border for the input */
  border-radius: 10px;
  font-size: 18px;
}

.activity-code-container button {
  background-color: #1a6f3f; /* White background for the button */
  border: none;
  border-radius: 10px;
  padding: 15px;
  margin-left: -6%;
  cursor: pointer;
  font-size: 20px;
  color: white; /* Text color same as the container background */
}

/* Adjust the focus styles for the input */
.activity-code-container input:focus {
  outline: none;
  border-color: #57b781; /* Lighter green border for focus */
}

/* This will cover the entire viewport with a semi-transparent grey background */
.loading-container {
  position: fixed; /* Use fixed positioning to cover the entire screen */
  top: 0;
  left: 0;
  width: 100vw; /* Viewport width */
  height: 100vh; /* Viewport height */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent grey background */
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  z-index: 1000; /* High z-index to make sure it's on top of other content */
}

/* Style for the loading SVG */
.loadingSvg {
  max-width: 200px; /* Set a max-width for the loading SVG */
  max-height: 200px; /* Set a max-height for the loading SVG */
  /* Add any other styles you may need for your SVG */
}
.middle {
  width: 100%;
}
