@import url(https://cdn.jsdelivr.net/npm/bootstrap-v4-grid-only@1.0.0/dist/bootstrap-grid.css);

.meeting-header {
  padding: var(--p2);
  font-size: var(--medium);
}
.text-left {
  text-align: left !important;
}
.meeting-eventTitle {
  font-weight: 600;
  font-size: 24px;
}
.standardSize
{
  height:30px !important;
   width:30px !important;
}
.ml-32
{
  margin-left:32px;
}

.ml-150
{
  margin-left:150px !important;
}
.align-center
{
  text-align:center !important;
  top:10%;
  position: absolute;
}
.name-bold
{
font-weight: 600;
}
.name
{
  font-family: 'Montserrat';
font-style: normal;
font-size: 18px;
line-height: 22px;
/* identical to box height */

text-align: right;
letter-spacing: -0.41px;
}
.green-text
{
  color: #1A6F3F;
}
.profileImg
{
  height: 30px !important;
  width: 30px !important;
  vertical-align: middle;
  border-style: none;
}
@media (max-width: 700px) 
{
  .activity-items
{
  max-width: 200px;
}
}
.m-3 {
  margin: 1rem !important;
}
.ml-3, .mx-3 {
  margin-left: 1rem !important;
}
.mr-3, .mx-3 {
  margin-right: 1rem !important;
}
.mt-3, .my-3 {
  margin-top: 1rem !important;
}
.ml-2, .mx-2 {
  margin-left: 0.5rem !important;
}
.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: 2rem;
  padding: 10px;
}