@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css);
.question-title {
  font-weight: 500;
  font-size: 24px;
  font-family: "Montserrat";
}
.rating-full,
.rating-empty {
  border-radius: 50%;
  background-color: white;
  transition: all 0.25s linear;
  margin: 0 v8px;
}

.rating-full div {
  background-color: #1a6f3f;
  border-radius: 50%;
  margin: 2px;
  width: 24px;
  height: 24px;
  display: none;
}

.rating-empty div {
  background-color: white;
  border-radius: 50%;
  margin: 2px;
  width: 24px;
  height: 24px;
  display: none;

}
.carousel .control-dots {
  bottom: -15px;
  left: -20px;
}
