@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css);
.question-title {
  font-weight: 500;
  font-size: 24px;
  font-family: "Montserrat";
  margin-top: 15px;
  margin-bottom: 15px;
}
.choose-text
{
  margin-top: 15px;
  margin-bottom: 15px;
}
.rating-full,
.rating-empty {
  border-radius: 50%;
  background-color: white;
  transition: all 0.25s linear;
  margin: 0 v8px;
}

.rating-full div {
  background-color: #1a6f3f;
  border-radius: 50%;
  margin: 2px;
  width: 24px;
  height: 24px;
}

.rating-empty div {
  background-color: white;
  border-radius: 50%;
  margin: 2px;
  width: 24px;
  height: 24px;
}

.m-3 {
  margin: 1rem !important;
}
.ml-3, .mx-3 {
  margin-left: 1rem !important;
}
.mr-3, .mx-3 {
  margin-right: 1rem !important;
}
.mt-3, .my-3 {
  margin-top: 1rem !important;
}
.ml-2, .mx-2 {
  margin-left: 0.5rem !important;
}
.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: 2rem;
  padding: 10px;
}
.rating
{
  margin-top: 25px;
  margin-bottom: 25px;

}